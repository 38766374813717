import { createRouter, createWebHashHistory} from "vue-router"
import { isLogin } from "../utils/auth"

const routes = [
    { path: "/", redirect: "/chart/search" },
    {
        path: "/chart/search",
        name: "chartSearch",
        component: () => import("../pages/chart/search"),
        meta: {
            auth: false
        }
    },
    {
        path: "/chart/detail/:no",
        name: "chartDetail",
        component: () => import("../pages/chart/detail"),
        meta: {
            auth: false
        }
    },
    {
        path: "/user/register",
        name: "userRegister",
        component: () => import("../pages/user/register"),
        meta: {
            auth: false
        }
    },
    {
        path: "/user/login",
        name: "userLogin",
        component: () => import("../pages/user/login"),
        meta: {
            auth: false
        }
    },
    {
        path: "/user/home",
        name: "userHome",
        component: () => import("../pages/user/home"),
        meta: {
            auth: true,
            roles: ['*']
        }
    },
    {
        path: "/user/info",
        name: "userInfo",
        component: () => import("../pages/user/info"),
        meta: {
            auth: true,
            roles: ['*']
        }
    },
    {
        path: "/user/houseSearch",
        name: "houseSearch",
        component: () => import("../pages/user/houseSearch"),
        meta: {
            auth: true,
            roles: ['*']
        }
    },
    {
        path: "/user/houseChart",
        name: "houseChart",
        component: () => import("../pages/user/houseChart"),
        meta: {
            auth: true,
            roles: ['*']
        }
    },
    {
        path: "/user/website",
        name: "website",
        component: () => import("../pages/user/website"),
        meta: {
            auth: true,
            roles: ['*']
        }
    }
]

const router = createRouter({
    history: createWebHashHistory(),
    routes: routes
})

// 路由拦截
router.beforeEach((to, from, next) => {
    if (to.fullPath === '/user/login' && isLogin()) {
        return router.replace('/user/home')
    }
    if (to.fullPath === '/user/register' && isLogin()) {
        return router.replace('/user/home')
    }
    if (to.meta.auth) {
        if (!isLogin()){
            return router.replace('/user/login')
        }
        next()
    }else {
        next()
    }
})

router.afterEach(() => {
    setTimeout(() => {
        const _hmt = _hmt || [];
        (function() {
            const hm = document.createElement('script');
            hm.src = "https://hm.baidu.com/hm.js?6fdf7029649acd95d83e1abc6b6ad9f0";
            const s = document.getElementsByTagName('script')[0];
            s.parentNode.insertBefore(hm, s)
        })()
    }, 0)
})

export default router
