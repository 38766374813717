const isLogin = () => {
    return !!localStorage.getItem('token');
};

const getToken = () => {
    return localStorage.getItem('token');
};

const setToken = (token) => {
    localStorage.setItem('token', token);
};

export const getRefreshToken = () => {
    return localStorage.getItem('refreshToken');
};

export const setRefreshToken = (refreshToken) => {
    localStorage.setItem('refreshToken', refreshToken);
};

const clearToken = () => {
    localStorage.removeItem('token');
};

export const clearRefreshToken = () => {
    localStorage.removeItem('refreshToken');
};

export { isLogin, getToken, setToken, clearToken };
